


















































































































































































































































































































































































































































































































































































import { Component, Provide } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Route } from 'vue-router';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import Honeybadger from 'honeybadger-js';

import store from '@/shared/store';
import {
  Campaign, CampaignsTab, CancellationFee,
} from '@/shared/store/campaign';
import { StoreFacebookPage } from '@/shared/store/facebook';

import defaultToast from '@/shared/lib/defaultToast';
import * as imgixFunctions from '@/shared/lib/imgixFunctions';

import {
  IFacebookPage,
  UserImage,
  FacebookPage as APIFacebookPage,
  LandingPage as APILandingPage,
  RejectedCampaign as APIRejectedCampaign,
  RejectedCampaignCorrectionRequest,
  RejectedCampaignCorrectionRequest_UserImage as RejectedCampaignCorrectionRequestUserImage,
  IRejectedCampaignCorrectionRequest_UserImage as IRejectedCampaignCorrectionRequestUserImage,
  RejectedCampaignCorrectionRequest_UserCopy as RejectedCampaignCorrectionRequestUserCopy,
  RejectedCampaign_UserCopy as RejectedCampaignUserCopy,
  Creative as APICreative,
  CreativeCustomization,
  MemberImage,
  Creative_Instagram_Placement as Placement, Copy,
} from '@/shared/gen/messages.pisa';

import AuthRequired from '@/shared/components/AuthRequired';
import BoilerplateAndComments from '@/shared/components/campaign/rejection/BoilerplateAndComments.vue';
import BuilderNavigation from '@/shared/components/builder/BuilderNavigation.vue';
import CancellationConfirmation from '@/zire/views/campaign/CancellationConfirmation.vue';
import FullScreenModal from '@/shared/components/common/FullScreenModal.vue';
import ChangeImageSelectionModal from '@/shared/components/builder/changeImageSelectionModal.vue';
import ImageOverlay from '@/shared/components/builder/ImageOverlay.vue';
import MaterialInputField from '@/shared/components/form/MaterialInputField.vue';
import OpenImageLibraryButton from '@/shared/components/builder/OpenImageLibraryButton.vue';
import SingleRetailerEntry from '@/shared/components/onboarding/SingleRetailerEntry.vue';
import SiteLayout from '@/shared/components/site/SiteLayout.vue';
import SocialConnections from '@/shared/components/common/SocialConnections.vue';
import SyncFacebookPageModals from '@/shared/components/modals/SyncFacebookPageModals.vue';
import Creative from '@/shared/components/common/Creative.vue';
import LoadingBackground from '@/shared/components/common/LoadingBackground.vue';
import DisabledAdOverlay from '@/shared/components/common/DisabledAdOverlay.vue';
import EditAdButton from '@/shared/components/builder/EditAdButton.vue';
import FacebookNewsFeedCreative from '@/shared/components/common/FacebookNewsFeedCreative.vue';
import InstagramFeedCreative from '@/shared/components/common/InstagramFeedCreative.vue';
import InstagramStoryCreative from '@/shared/components/common/InstagramStoryCreative.vue';
import EditAdModal from '@/shared/components/builder/EditAdModal.vue';

interface LandingPageStruct {
  wasRejected: boolean;
  initialValue: any;
  value: any;
  retailer: any;
  isValid: boolean;
  validating: boolean;
  checked: boolean;
}

@Component({
  mixins: [validationMixin],
  components: {
    BoilerplateAndComments,
    BuilderNavigation,
    CancellationConfirmation,
    FullScreenModal,
    ChangeImageSelectionModal,
    ImageOverlay,
    MaterialInputField,
    OpenImageLibraryButton,
    SingleRetailerEntry,
    SiteLayout,
    SocialConnections,
    SyncFacebookPageModals,
    Creative,
    LoadingBackground,
    DisabledAdOverlay,
    EditAdButton,
    FacebookNewsFeedCreative,
    InstagramFeedCreative,
    InstagramStoryCreative,
    EditAdModal,
  },
  validations: {
    artistName: { required },
    releaseName: { required },
  },
})

export default class RejectedCampaign extends AuthRequired {
  $refs!: {
    syncFacebookPageModals: any,
    socialAccountsSection: HTMLElement,
    releaseDetailsSection: HTMLElement,
    imagesSection: HTMLElement,
    copySection: HTMLElement,
    cancelConfirmationModal: FullScreenModal,
    imageSelection: ChangeImageSelectionModal
  };

  rejectedCampaign: APIRejectedCampaign = new APIRejectedCampaign();

  cancelCampaignDetails: Campaign = {
    landingPages: [],
    adNetworks: [],
  };

  @Getter('facebook/getSyncedPages') syncedPages: StoreFacebookPage[];

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  @Getter('layout/isTouch') isTouch: boolean;

  @Getter('imageLibrary/getImageByID') getUserImageById: Function;

  @Getter('recommendedAds/getMemberImageById') getMemberImageById: Function;

  errors: any = {
    artistName: [],
    releaseName: [],
  };

  cancellationFee: CancellationFee = { currency: '', amount: 0 };

  canceling: boolean = false;

  canceled: boolean = false;

  artistName: string = '';

  releaseName: string = '';

  initialArtistName: string = '';

  initialReleaseName: string = '';

  landingPages: LandingPageStruct[] = [];

  rejectedCopy: RejectedCampaignUserCopy[] = [];

  facebookPageId: string = '';

  buttonText: string = 'Submit for Review';

  fireShakeSocialAccounts: boolean = false;

  fireShakeReleaseDetails: boolean = false;

  fireShakeImages: boolean = false;

  fireShakeCopy: boolean = false;

  isProcessing: boolean = false;

  imageIdToBeRemoved: string = '';

  imageIdToBeReplaced: string = '';

  correctedImages: IRejectedCampaignCorrectionRequestUserImage[] = [];

  correctedCopy: RejectedCampaignCorrectionRequestUserCopy[] = [];

  creativeToEdit: APICreative = {} as APICreative;

  selectedUserImage: UserImage|null = null;

  selectedMemberImage: MemberImage|null = null;

  beforeRouteEnter(to: Route, from: Route, next: any) {
    Promise.all([
      store.dispatch('campaign/getRejectedCampaign', to.params.id),
      store.dispatch('imageLibrary/loadLibrary'),
      store.dispatch('recommendedAds/listMemberImages', to.params.id),
    ]).then((values) => {
      const rejectedCampaign: APIRejectedCampaign = values[0];
      next((vm: RejectedCampaign) => {
        // eslint-disable-next-line no-param-reassign
        vm.rejectedCampaign = rejectedCampaign;
        vm.setInitialValues();
      });
    }).catch(() => {
      defaultToast();
      next(false);
    });
  }

  get placement() {
    return {
      STORY: Placement.STORY,
      FEED: Placement.FEED,
    };
  }

  get displaySocialAccountsSection() {
    return this.rejectedCampaign.facebookPage && this.rejectedCampaign.facebookPage.rejected;
  }

  get displayReleaseDetailsSection() {
    return this.rejectedCampaign.releaseData.some((i) => i.rejected);
  }

  get displayLandingPagesSection() {
    return this.rejectedCampaign.landingPages.some((i) => i.rejected);
  }

  get displayImagesSection() {
    return this.rejectedCampaign.rejectedUserImages.length > 0;
  }

  get displayCopySection() {
    return this.rejectedCampaign.rejectedUserCopy.length > 0;
  }

  get isTabletOrAbove() {
    return !['xs', 'sm'].includes(this.$mq);
  }

  get rejectedDetailsReasons() {
    const rejectionReasons = [];
    if (this.rejectedCampaign.releaseDataComments) {
      rejectionReasons.push(this.rejectedCampaign.releaseDataComments);
    }
    if (this.rejectedCampaign.landingPagesComments) {
      rejectionReasons.push(this.rejectedCampaign.landingPagesComments);
    }
    return rejectionReasons;
  }

  get rejectedDisplayCopy() {
    return this.rejectedCopy.filter((rc) => rc.creative.network === 'DISPLAY');
  }

  get rejectedSocialCopy() {
    return this.rejectedCopy.filter((rc) => rc.creative.network === 'FACEBOOK' || rc.creative.network === 'INSTAGRAM');
  }

  get disabledImageList() {
    return [
      ...this.rejectedCampaign.rejectedUserImages.map((i) => i.id),
      ...this.rejectedCampaign.userImageIds,
      ...this.correctedImagesWithoutRemoved.map((i) => i.replacement!.id),
    ];
  }

  get selectedImageList() {
    return this.correctedImages
      .filter((i) => i.id === this.imageIdToBeReplaced)
      .map((i) => i.replacement);
  }

  getThumbnailUrl(image: UserImage) {
    return imgixFunctions.getImageThumbnailUrl(image.url);
  }

  creativeImage(creative: APICreative) {
    if (creative.customization.userImageId) {
      return this.getUserImageById(creative.customization.userImageId);
    }
    if (creative.customization.memberImageId) {
      return this.getMemberImageById(creative.customization.memberImageId);
    }
    return undefined;
  }

  editAd(rejectedCreative: APICreative, creativeId: string) {
    const { userImageId, memberImageId } = rejectedCreative.customization;

    const correctedCustomization = this.correctedCustomization(creativeId);
    if (correctedCustomization) {
      const {
        copy,
        enabled,
      } = correctedCustomization;
      const duplicateCustomization = new CreativeCustomization({
        copy,
        enabled,
        userImageId,
        memberImageId,
      });
      const correctedCreative = new APICreative({
        id: rejectedCreative.id,
        network: rejectedCreative.network,
        properties: rejectedCreative.properties,
        display: rejectedCreative.display,
        facebook: rejectedCreative.facebook,
        instagram: rejectedCreative.instagram,
        customization: duplicateCustomization,
      });

      this.creativeToEdit = correctedCreative;
    } else {
      this.creativeToEdit = rejectedCreative;
    }

    if (userImageId && userImageId !== '0') {
      this.selectedUserImage = this.getUserImageById(userImageId);
      this.selectedMemberImage = null;
    } else if (memberImageId) {
      this.selectedUserImage = null;
      this.selectedMemberImage = this.getMemberImageById(memberImageId);
    }
    this.$root.$emit('bv::show::modal', 'edit-ad-modal');
  }

  invalidCopy(creative: APICreative) {
    const rejectedIdx = this.rejectedCopy.findIndex((rc) => rc.creative.id === creative.id);
    const invalidCopy: Copy = {} as Copy;
    if (rejectedIdx > -1) {
      const rejectedCopy = this.rejectedCopy[rejectedIdx];

      if (rejectedCopy.headerRejected) {
        invalidCopy.header = rejectedCopy.creative.customization.copy.header;
      }
      if (rejectedCopy.subheaderRejected) {
        invalidCopy.subheader = rejectedCopy.creative.customization.copy.subheader;
      }
    }
    return invalidCopy;
  }

  clearCreative() {
    this.creativeToEdit = {} as APICreative;
  }

  validateLandingPage(landingPage: LandingPageStruct) {
    const { value } = landingPage;
    /* eslint-disable no-param-reassign */
    landingPage.validating = true;
    landingPage.isValid = false;
    landingPage.checked = false;
    this.$store.dispatch('linkValidation/validate', landingPage.value).then((valid) => {
      if (landingPage.value === value) {
        landingPage.validating = false;
        landingPage.isValid = valid;
        landingPage.checked = true;
      }
    });
    /* eslint-enable no-param-reassign */
  }

  setInitialValues() {
    this.rejectedCampaign.releaseData.forEach((i) => {
      if (i.key === 'artistName') {
        this.artistName = i.value;
        this.initialArtistName = i.value;
      }
      if (i.key === 'releaseName') {
        this.releaseName = i.value;
        this.initialReleaseName = i.value;
      }
    });

    const landingPages: LandingPageStruct[] = [];
    this.rejectedCampaign.landingPages.forEach((i) => {
      landingPages.push({
        wasRejected: i.rejected,
        initialValue: i.landingPage.url,
        value: i.landingPage.url,
        retailer: i.landingPage.retailer,
        isValid: !i.rejected,
        validating: false,
        checked: true,
      });
    });
    this.landingPages = landingPages;

    if (this.rejectedCampaign.rejectedUserImages.length > 0) {
      this.correctedImages = this.rejectedCampaign.rejectedUserImages.map((i) => new RejectedCampaignCorrectionRequestUserImage({
        id: i.id,
        replacement: i,
      }));
    }

    const rejectedCopy: RejectedCampaignUserCopy[] = [];
    if (this.rejectedCampaign.rejectedUserCopy.length > 0) {
      this.rejectedCampaign.rejectedUserCopy.forEach((copyObj) => {
        const copy = new Copy({
          header: copyObj.creative.customization.copy.header,
          subheader: copyObj.creative.customization.copy.subheader,
          showSubheader: copyObj.creative.customization.copy.showSubheader,
        });
        const rejectedCustomization = new CreativeCustomization({
          copy,
          userImageId: copyObj.creative.customization.userImageId,
          memberImageId: copyObj.creative.customization.memberImageId,
          enabled: copyObj.creative.customization.enabled,
        });
        const rejectedCreative = new APICreative({
          id: copyObj.creative.id,
          properties: copyObj.creative.properties,
          network: copyObj.creative.network,
          display: copyObj.creative.display,
          facebook: copyObj.creative.facebook,
          instagram: copyObj.creative.instagram,
          customization: rejectedCustomization,
        });

        const rejected: RejectedCampaignUserCopy = new RejectedCampaignUserCopy({
          creative: rejectedCreative,
          headerRejected: copyObj.headerRejected,
          subheaderRejected: copyObj.subheaderRejected,
        });
        rejectedCopy.push(rejected);
      });
    }
    this.rejectedCopy = rejectedCopy;
  }

  rejectedField(field: string) {
    return this.rejectedCampaign.releaseData.some((i) => i.key === field && i.rejected);
  }

  get hasInvalidSocial() {
    if (this.displaySocialAccountsSection) {
      let isValid = true;
      if (this.rejectedCampaign.facebookPage.rejected) {
        isValid = (this.rejectedCampaign.facebookPage.facebookPage.pageId !== this.facebookPageId) && (this.facebookPageId !== '');
      }
      return !isValid;
    }
    return false;
  }

  get hasInvalidDetails() {
    if (this.displayReleaseDetailsSection || this.displayLandingPagesSection) {
      let isValid = true;
      if (this.rejectedField('artistName')) {
        isValid = isValid && (this.artistName !== this.initialArtistName && !this.$v.artistName!.$invalid && this.$v.artistName!.$dirty);
      }
      if (this.rejectedField('releaseName')) {
        isValid = isValid && (this.releaseName !== this.initialReleaseName && !this.$v.releaseName!.$invalid && this.$v.releaseName!.$dirty);
      }
      isValid = isValid && this.landingPages.every((lp) => !this.isInvalidLandingPage(lp))
        && this.landingPages.every((lp) => !this.isValidatingLandingPage(lp));
      return !isValid;
    }
    return false;
  }

  get releaseDetailsBoilerplate() {
    const artistNameRejected = this.rejectedField('artistName');
    const releaseNameRejected = this.rejectedField('releaseName');

    let landingPageAddendum = '';
    if (this.displayLandingPagesSection) {
      // eslint-disable-next-line max-len,quotes
      landingPageAddendum = ` In addition, your campaign's link can't point to a site that isn't music-related or that doesn't feature your release.`;
    }

    if (artistNameRejected && releaseNameRejected) {
      // eslint-disable-next-line max-len
      return `Artist names and release titles can’t contain any reference to adult content, illegal activity, hate speech, or profanity.${landingPageAddendum} Please update your artist name, release's title and campaign's URL before resubmitting for final approval.`;
    }
    if (artistNameRejected) {
      // eslint-disable-next-line max-len
      return `Artist names can’t contain any reference to adult content, illegal activity, hate speech, or profanity.${landingPageAddendum} Please update your artist name and campaign's URL before resubmitting for final approval.`;
    }
    if (releaseNameRejected) {
      // eslint-disable-next-line max-len
      return `Release titles can’t contain any reference to adult content, illegal activity, hate speech, or profanity.${landingPageAddendum} Please update your release's title and campaign's URL before resubmitting for final approval.`;
    }
    // If we're here, we either aren't displaying the release details section, or we've got a bad link...
    // eslint-disable-next-line max-len,quotes
    return `Your campaign's link can't point to a site that isn't music-related or that doesn't feature your release. Please update your campaign's URL before resubmitting for final approval.`;
  }

  get imagesBoilerplate() {
    const numTotalImages = this.rejectedCampaign.userImageIds.length;
    const numRejectedImages = this.rejectedCampaign.rejectedUserImages.length;
    const messageCopy = 'Images should be high-res and can’t contain any adult content, obscene gestures, or infringe on copyrighted material.';
    if (numTotalImages > 1) {
      if (numRejectedImages > 1) {
        return `${messageCopy} Please replace or remove the flagged images before resubmitting for final approval.`;
      }
      return `${messageCopy} Please replace or remove the flagged image before resubmitting for final approval.`;
    }
    return `${messageCopy} Please replace or remove your image before resubmitting for final approval.`;
  }

  get copyBoilerplate() {
    const numRejectedCopy = this.rejectedCampaign.rejectedUserCopy.length;
    if (numRejectedCopy > 1) {
      return 'Ad copy can\'t infringe on copyrighted material or contain any reference to tobacco, '
        + 'adult content, illegal activity, hate speech, or profanity. Please update your copy or turn '
        + 'off the impacted ads before resubmitting for final approval.';
    }

    return 'Ad copy can\'t infringe on copyrighted material or contain any reference to tobacco, adult '
      + 'content, illegal activity, hate speech, or profanity. Please update your copy or turn off the '
      + 'impacted ad before resubmitting for final approval.';
  }

  get hasInvalidImages() {
    return this.correctedImages.some((i) => i.replacement && i.replacement.id === i.id);
  }

  get hasInvalidCopy() {
    let invalidCopy = false;
    if (this.rejectedCampaign.rejectedUserCopy.length > this.correctedCopy.length) {
      return true;
    }
    this.rejectedCopy.forEach((rc) => {
      this.correctedCopy.forEach((cc) => {
        if (rc.creative.id === cc.creativeId) {
          if (cc.customization.enabled) {
            if (rc.headerRejected) {
              if (rc.creative.customization.copy.header === cc.customization.copy.header) {
                invalidCopy = true;
              }
            }
            if (rc.subheaderRejected) {
              if (rc.creative.customization.copy.subheader === cc.customization.copy.subheader) {
                invalidCopy = true;
              }
            }
          }
        }
      });
    });
    return invalidCopy;
  }

  get canRemoveAllUserImages(): boolean {
    return this.rejectedCampaign.flags.canRemoveAllUserImages;
  }

  get numRejectedDisplayCopy() {
    return this.rejectedCopy.filter((rc) => rc.creative.network === 'DISPLAY').length;
  }

  get numDisabledDisplayCopy() {
    let numDisabled: number = 0;
    this.rejectedCopy.forEach((rc) => {
      if (rc.creative.network === 'DISPLAY') {
        const correctedCustomization = this.correctedCustomization(rc.creative.id);
        if (correctedCustomization) {
          if (!correctedCustomization.enabled) {
            numDisabled += 1;
          }
        }
      }
    });
    return numDisabled;
  }

  get numRemainingDisplayCopy() {
    return this.numRejectedDisplayCopy - this.numDisabledDisplayCopy;
  }

  get canDisableDisplayCopy() {
    return this.canRemoveAllDisplayCopy || this.numRemainingDisplayCopy > 1;
  }

  get canRemoveAllDisplayCopy() {
    return !!this.rejectedCampaign.flags.canRemoveAllDisplayCopy;
  }

  get numRejectedFacebookCopy() {
    return this.rejectedCopy.filter((rc) => rc.creative.network === 'FACEBOOK').length;
  }

  get numDisabledFacebookCopy() {
    let numDisabled: number = 0;
    this.rejectedCopy.forEach((rc) => {
      if (rc.creative.network === 'FACEBOOK') {
        const correctedCustomization = this.correctedCustomization(rc.creative.id);
        if (correctedCustomization) {
          if (!correctedCustomization.enabled) {
            numDisabled += 1;
          }
        }
      }
    });
    return numDisabled;
  }

  get numRemainingFacebookCopy() {
    return this.numRejectedFacebookCopy - this.numDisabledFacebookCopy;
  }

  get canDisableFacebookCopy() {
    return this.canRemoveAllFacebookCopy || this.numRemainingFacebookCopy > 1;
  }

  get canRemoveAllFacebookCopy() {
    return !!this.rejectedCampaign.flags.canRemoveAllFacebookCopy;
  }

  get numRejectedInstagramCopy() {
    return this.rejectedCopy.filter((rc) => rc.creative.network === 'INSTAGRAM').length;
  }

  get numDisabledInstagramCopy() {
    let numDisabled: number = 0;
    this.rejectedCopy.forEach((rc) => {
      if (rc.creative.network === 'INSTAGRAM') {
        const correctedCustomization = this.correctedCustomization(rc.creative.id);
        if (correctedCustomization) {
          if (!correctedCustomization.enabled) {
            numDisabled += 1;
          }
        }
      }
    });
    return numDisabled;
  }

  get numRemainingInstagramCopy() {
    return this.numRejectedInstagramCopy - this.numDisabledInstagramCopy;
  }

  get canDisableInstagramCopy() {
    return this.canRemoveAllInstagramCopy || this.numRemainingInstagramCopy > 1;
  }

  get canRemoveAllInstagramCopy() {
    return !!this.rejectedCampaign.flags.canRemoveAllInstagramCopy;
  }

  cannotDisable(c: APICreative) {
    switch (c.network) {
      case 'DISPLAY':
        return !this.canDisableDisplayCopy;
      case 'FACEBOOK':
        return !this.canDisableFacebookCopy;
      case 'INSTAGRAM':
        return !this.canDisableInstagramCopy;
      default:
        return false;
    }
  }

  canDisableCreative() {
    return !this.cannotDisable(this.creativeToEdit);
  }

  isValidatingLandingPage(landingPage: LandingPageStruct) {
    if (landingPage.validating) {
      return true;
    }
    return false;
  }

  isInvalidLandingPage(landingPage: LandingPageStruct) {
    if (!landingPage.wasRejected) {
      return false;
    }
    if (landingPage.validating) {
      return false;
    }
    if (landingPage.isValid && !landingPage.checked) {
      return false;
    }
    if (landingPage.value === landingPage.initialValue) {
      return true;
    }
    if (!landingPage.isValid) {
      return true;
    }
    return false;
  }

  rightIconForLandingPage(landingPage: LandingPageStruct) {
    if (this.isInvalidLandingPage(landingPage)) {
      return ['fas', 'exclamation-triangle'];
    }
    return [];
  }

  rightIconClassForLandingPage(landingPage: LandingPageStruct) {
    if (this.isInvalidLandingPage(landingPage)) {
      return 'error';
    }
    return '';
  }

  delayTouchUntilBlur($v: any) {
    $v.$reset();
  }

  touchElement($v: any) {
    // eslint-disable-next-line no-param-reassign
    $v.$model = $v.$model.trim();
    $v.$touch();
  }

  resetElement($v: any) {
    $v.$reset();
  }

  get correctedImagesWithoutRemoved() {
    return this.correctedImages.filter((i) => i.replacement);
  }

  get allowRemoveImage() {
    return this.canRemoveAllUserImages || this.correctedImagesWithoutRemoved.length > 1;
  }

  changeImage(imageId: string) {
    this.imageIdToBeReplaced = imageId;
    this.$nextTick(() => {
      this.$root.$emit('bv::show::modal', 'change-image-selection-modal');
    });
  }

  setImageChanges(imageLists: { userImages: UserImage[] }) {
    this.correctedImages.forEach((i) => {
      if (i.id === this.imageIdToBeReplaced) {
        const [selectedImage] = imageLists.userImages;
        // eslint-disable-next-line no-param-reassign
        i.replacement = selectedImage;
      }
    });
    this.imageIdToBeReplaced = '';
  }

  removeImage(imageId: string, evt: Event) {
    this.cancelRemoval();
    evt.stopImmediatePropagation();
    evt.preventDefault();
    this.imageIdToBeRemoved = imageId;
    document.body.addEventListener('click', this.cancelRemoval);
  }

  cancelRemoval() {
    this.imageIdToBeRemoved = '';
    document.body.removeEventListener('click', this.cancelRemoval);
  }

  confirmRemoveImage(imageId: string) {
    this.correctedImages.forEach((i) => {
      if (i.id === imageId) {
        // eslint-disable-next-line no-param-reassign
        i.replacement = undefined;
      }
    });
  }

  rejectedUserCopy(creativeId: string) {
    const rejectedIdx = this.rejectedCopy.findIndex((rc) => rc.creative.id === creativeId);
    return this.rejectedCopy[rejectedIdx];
  }

  rejectedCustomization(creativeId: string) {
    return this.rejectedUserCopy(creativeId).creative.customization;
  }

  correctedUserCopy(creativeId: string) {
    const correctedIdx = this.correctedCopy.findIndex((cc) => cc.creativeId === creativeId);
    if (correctedIdx > -1) {
      return this.correctedCopy[correctedIdx];
    }
    return null;
  }

  correctedCustomization(creativeId: string) {
    const correctedCopy = this.correctedUserCopy(creativeId);
    if (correctedCopy) {
      return correctedCopy.customization;
    }
    return null;
  }

  isInvalidCopy(creativeId: string) {
    const rejectedUserCopy = this.rejectedUserCopy(creativeId);
    const rejectedCustomization = this.rejectedCustomization(creativeId);
    const correctedCustomization = this.correctedCustomization(creativeId);
    let corrected = true;
    if (correctedCustomization) {
      if (rejectedUserCopy.headerRejected) {
        if (correctedCustomization.copy.header === rejectedCustomization.copy.header) {
          corrected = false;
        }
      }
      if (rejectedUserCopy.subheaderRejected) {
        if (correctedCustomization.copy.subheader === rejectedCustomization.copy.subheader) {
          corrected = false;
        }
      }
    } else {
      corrected = false;
    }
    return !corrected;
  }

  isEnabled(creativeId: string) {
    const rejectedCustomization = this.rejectedCustomization(creativeId);
    const correctedCustomization = this.correctedCustomization(creativeId);

    if (correctedCustomization) {
      return correctedCustomization.enabled;
    }
    return rejectedCustomization.enabled;
  }

  contentOverride(creativeId: string) {
    const rejectedCustomization = this.rejectedCustomization(creativeId);
    const correctedCustomization = this.correctedCustomization(creativeId);

    let { header, subheader } = rejectedCustomization.copy;

    if (correctedCustomization) {
      header = correctedCustomization.copy.header;
      subheader = correctedCustomization.copy.subheader;
    }

    return {
      copy: {
        header,
        subheader,
      },
    };
  }

  updateCopy(creative: APICreative) {
    const creativeId = creative.id;

    // const creativeRefIdx = this.$refs.displayCreatives.findIndex((dc) => dc.id === `creative:${creativeId}e`);
    // if (creativeRefIdx > -1) {
    //   this.$refs.displayCreatives[creativeRefIdx].reRegisterCreative();
    // }
    const { copy, enabled } = creative.customization;
    const customization: CreativeCustomization = new CreativeCustomization({
      copy,
      enabled,
    });
    const correction: RejectedCampaignCorrectionRequestUserCopy = new RejectedCampaignCorrectionRequestUserCopy({
      creativeId,
      customization,
    });
    const creativeIdx = this.correctedCopy.findIndex((cc) => cc.creativeId === creativeId);
    if (creativeIdx > -1) {
      if (enabled) {
        // case where they've disabled, then re-enabled the creative without updating the copy
        const rejectedInstance = this.rejectedUserCopy(creativeId);
        if ((rejectedInstance.headerRejected && rejectedInstance.creative.customization.copy.header === copy.header)
          || (rejectedInstance.subheaderRejected && rejectedInstance.creative.customization.copy.subheader === copy.subheader)) {
          this.correctedCopy.splice(creativeIdx, 1);
        } else {
          this.correctedCopy.splice(creativeIdx, 1, correction);
        }
      } else {
        this.correctedCopy.splice(creativeIdx, 1, correction);
      }
    } else {
      this.correctedCopy.push(correction);
    }
  }

  openImageLibraryModal(evt: Event) {
    evt.preventDefault();
    this.$root.$emit('bv::show::modal', 'image-upload-modal', evt.target);
  }

  get syncedPage(): IFacebookPage | undefined {
    if (this.facebookPageId) {
      const syncedPage = this.syncedPages.find((i) => i.pageId === this.facebookPageId);
      if (syncedPage) {
        const mappedPage: any = {
          pageId: syncedPage.pageId,
          name: syncedPage.name,
          link: syncedPage.link,
          pictureUrl: syncedPage.pictureUrl,
        };
        if (syncedPage.instagram && syncedPage.instagram.length > 0) {
          const instagram = syncedPage.instagram[0];
          mappedPage.instagram = {
            id: instagram.id,
            username: instagram.username,
            pictureUrl: instagram.pictureUrl,
          };
        }
        return mappedPage;
      }
    } else if (this.rejectedCampaign.facebookPage) {
      return this.rejectedCampaign.facebookPage.facebookPage;
    }
    return undefined;
  }

  updateFacebookPageId(page: StoreFacebookPage) {
    this.facebookPageId = page.pageId;
    if (this.rejectedCampaign.facebookPage && this.rejectedCampaign.facebookPage.facebookPage.pageId === this.facebookPageId) {
      this.facebookPageId = '';
    }
  }

  goBack() {
    this.$router.push({ name: 'campaigns' });
  }

  onSubmit() {
    // social can be corrected by the user outside this flow, so we don't force them to change
    // if (this.hasInvalidSocial) {
    //   this.$refs.socialAccountsSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //   this.fireShakeSocialAccounts = true;
    //   setTimeout(() => {
    //     this.fireShakeSocialAccounts = false;
    //   }, 1500);
    //   return;
    // }
    if (this.hasInvalidDetails) {
      this.$refs.releaseDetailsSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.fireShakeReleaseDetails = true;
      setTimeout(() => {
        this.fireShakeReleaseDetails = false;
      }, 1500);
      return;
    }
    if (this.hasInvalidImages) {
      this.$refs.imagesSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.fireShakeImages = true;
      setTimeout(() => {
        this.fireShakeImages = false;
      }, 1500);
      return;
    }
    if (this.hasInvalidCopy) {
      this.$refs.copySection.scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.fireShakeCopy = true;
      setTimeout(() => {
        this.fireShakeCopy = false;
      }, 1500);
      return;
    }
    this.isProcessing = true;

    const releaseData: { artistName?: string, releaseName?: string } = {};
    if (this.rejectedField('artistName')) {
      releaseData.artistName = this.artistName;
    }
    if (this.rejectedField('releaseName')) {
      releaseData.releaseName = this.releaseName;
    }

    const landingPages: APILandingPage[] = [];
    this.landingPages.forEach((lp: LandingPageStruct) => {
      landingPages.push(new APILandingPage({
        retailer: lp.retailer,
        url: lp.value,
      }));
    });

    const userCopy: RejectedCampaignCorrectionRequestUserCopy[] = this.correctedCopy;

    const payload = new RejectedCampaignCorrectionRequest({
      id: this.rejectedCampaign.id,
      releaseData,
      landingPages,
      userImages: this.correctedImages as RejectedCampaignCorrectionRequestUserImage[],
      userCopy,
    });
    if (this.facebookPageId) {
      payload.facebookPage = new APIFacebookPage({
        pageId: this.facebookPageId,
      });
    }
    this.$store.dispatch('campaign/submitRejectedCampaign', payload).then(() => {
      this.$router.replace({ name: 'campaigns' });
    }).catch((error) => {
      const context: any = {};
      if (error && error.code) {
        context.code = error.code;
      }
      if (error && error.meta) {
        context.meta = error.meta;
      }
      Honeybadger.notify(error, 'RejectedCampaignError', { context });
    }).finally(() => {
      this.isProcessing = false;
    });
  }

  cancelClick() {
    let promise = Promise.resolve();
    if (this.cancelCampaignDetails.id !== this.rejectedCampaign.id) {
      // Lazy load the campaign details for cancellation.
      promise = this.$store.dispatch('campaign/load', this.rejectedCampaign.id)
        .then(() => {
          this.cancelCampaignDetails = this.$store.getters['campaign/campaignDetails'];
        });
    }
    promise
      .then(() => this.$store.dispatch('campaign/beginCancellation', this.rejectedCampaign.id))
      .then((cancellationFee) => {
        this.canceling = true;
        this.cancellationFee = cancellationFee;
        this.$refs.cancelConfirmationModal.show();
      })
      .catch(() => {
        defaultToast('Failed to cancel campaign.  Please try again.');
      });
  }

  cancelCampaign() {
    return this.$store.dispatch('campaign/completeCancellation', this.rejectedCampaign.id)
      .catch(() => {
        defaultToast('Failed to cancel campaign.  Please try again.');
      });
  }

  @Provide('cancelCampaign') cancelCampaignInjection: Function = this.cancelCampaign;

  submitFeedback(feedback: any) {
    return this.$store.dispatch('campaign/submitFeedback', feedback);
  }

  @Provide('submitFeedback') submitFeedbackInjection: Function = this.submitFeedback;

  handleCancelConfirmationClosed() {
    this.canceling = false;
    if (this.canceled) {
      this.$store.dispatch('campaign/setSelectedTab', CampaignsTab.Completed).then(() => {
        this.$router.push({ name: 'campaigns' });
      });
    }
  }
}
