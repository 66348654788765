













































import { Component, Prop, Vue } from 'vue-property-decorator';
import InputField from '@/shared/components/onboarding/InputField.vue';

@Component({
  mixins: [],
  components: {
    InputField,
  },
  props: {},
})
export default class SingleRetailerEntry extends Vue {
  @Prop({ default: '' })
  value: string;

  @Prop({ default: '' })
  fieldRef: string;

  @Prop({ default: '' })
  placeholder: string;

  @Prop({ default: '' })
  invalidText: string;

  @Prop({ default: false })
  valid: boolean;

  @Prop({ default: false })
  disabled: boolean;

  @Prop({ default: false })
  processing: boolean;

  @Prop({ default: true })
  showVerifyCheck: boolean;

  @Prop({ default: () => [] })
  forceRightIcon: Array<String>;

  @Prop({ default: '' })
  forceRightIconClass: string;

  @Prop({ default: 1 })
  tabindex: number;

  @Prop({ default: false })
  processed: boolean;

  @Prop({ default: false })
  error: boolean;

  @Prop({ default: false })
  corrected: boolean;

  @Prop({ default: false })
  autoFocus: boolean;

  @Prop({ default: '' })
  containerClass: string;

  @Prop({ default: '' })
  label: string;

  @Prop({ default: false, type: Boolean })
  hideLinkIcon: boolean;

  localValue: any = '';

  get rightIcon() {
    if (this.forceRightIcon && this.forceRightIcon.length > 0) {
      return this.forceRightIcon;
    }
    if (this.processing) {
      return ['fas', 'spinner'];
    }
    if (this.processed && this.valid && this.showVerifyCheck) {
      return ['far', 'check'];
    }
    return [];
  }

  get rightIconClass() {
    if (this.forceRightIconClass) {
      return this.forceRightIconClass;
    }
    if (this.processing) {
      return 'fa-spin purple';
    }
    return '';
  }

  mounted() {
    this.setLocalValueToProp();
  }

  updated() {
    this.setLocalValueToProp();
  }

  setLocalValueToProp() {
    this.localValue = this.value;
  }

  handlePasteInput() {
    this.validateLink();
  }

  validateLink() {
    this.$emit('validate-link', this.localValue);
  }

  emitSubmit() {
    this.$emit('submit');
  }

  setValue(value: any) {
    this.localValue = value;
    this.$emit('input', this.localValue);
  }
}
