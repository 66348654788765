
























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BoilerplateAndComments extends Vue {
  showMore = false;

  @Prop({ default: false })
  hideToggle: boolean;

  beforeMount() {
    this.showMore = this.isTabletOrAbove || this.hideToggle;
  }

  updated() {
    if (this.hideToggle) {
      this.showMore = true;
    }
  }

  get isTabletOrAbove() {
    return !['xs', 'sm'].includes(this.$mq);
  }
}
